import React, { useState } from 'react'
import ArrowDown from '../asset/arrow_down.svg'
import Arrowup from '../asset/arrow_up.svg'

export default function Faq() {

    const [selected, setSelected] = useState(-1)
    const faqs = [{
        id: 1, title: "How does it work? ",
        answer: [
            { title: "When you have our 12.5kg composite gas cylinder and you make an order for a refill, we'll bring a filled one to you and take your empty cylinder." },
            { title: "Think of it like your dispenser water where you return your empty bottle and get a new one filled." }]
    },
    {
        id: 2, title: "What makes our cylinder different from steel cylinders?",
        answer: [
            { title: "Our composite gas cylinder is different from other steel cylinders in unique ways." },
            { title: "With our composite cylinder, you have a very low risk of fire explosion as it’s encased in a fiber-reinforced polymer, which will help to prevent even tiny leaks from very thin metal cylinders and add extra tensile strength, unlike the steel cylinder that’s only covered with steel and is very prone to explosion, which can lead to loss of lives and properties." },
            { title: "Our composite gas cylinder is also very light in weight making it able for easy transmission. Our cylinder is also non-corrosive, compared to the steel cylinder that easily gets stained and rusty." }]
    },
    {
        id: 3, title: "How much is the cylinder? ", answer: [
            { title: "Our composite gas cylinder is sold for just ₦40,000 on the Mezopay app, but we allow  installments payments over three months with 0% interest." }]
    },
    {
        id: 4, title: "How much is your cooking gas?",
        answer: [{ title: "The price of our cooking gas varies due to fluctuations in gas prices. But be rest assured that we will always sell to you at a very affordable price. Please always check our social media pages or our app for price updates." }]
    },
    {
        id: 5, title: "Time of delivery",
        answer: [{ title: "Our delivery takes not less than 30 mins to get to your location, but if there’s any delay in delivery, we’ll definitely inform you." }]
    },
    {
        id: 6, title: "Can I get the composite cylinder without gas?",
        answer: [{ title: "Yes, you can get our composite cylinder without gas, but you might not enjoy the benefits that our subscribers enjoy like free delivery of gas, free bank transfers of up to 50 times, discount on gas sales, etc." }]
    },
    {
        id: 7,
        title: "How do I make a payment?",
        answer: [
            { title: "Simply download the Mezopay app, sign up, place your order, and make payments with your card, or fund your wallet." }]
    },
    {
        id: 8,
        title: "Is there a return policy?",
        answer: [{ title: "Yes, there is a return policy for all our subscribers." }]
    },
    {
        id: 9,
        title: "Can I pay in installments for the composite cylinder?",
        answer: [{ title: "Yes, you can pay in installments. You pay 50% initial deposit and spread the other payments over three (3) months with 0% interest." }]
    },
        // {
        //     id: 10,
        //     title:"Can I transfer?",
        //     answer:["Yes, you can use the Mezopay app to make transfers to other banks, and if you’re part of our subscribers, you can enjoy up to 50 free transfers."]
        // },
        // {
        //     id: 7,
        //     title:"Can it work without data?",
        //     answer:[]
        // },
        // {
        //     id: 7,
        //     title:"How do I make a payment?",
        //     answer:[]
        // },
    ]
    return (
        <div className='flex flex-col justify-center align-center my-10 px-10'>
            <h1 className='font-bold w-full text-center text-3xl font-bold mt-20'>Frequently Asked Questions</h1>
            {faqs.map((item, index) => {
                return (<div key={index} onClick={() => {
                   index === selected ? setSelected(-1) : setSelected(index)
                    }} className='cursor-pointer'>
                    <div className='flex justify-between md:mx-30 lg:mx-60  my-4 p-3 border-b border-black'>
                        <p className='font-bold text-left pr-4'>{item.title}</p>
                        <img src={selected === index ? Arrowup : ArrowDown} alt="pointer" />
                    </div>
                    {Array.isArray(item.answer) && item.answer.map(answerItem => selected === index && (
                        <p className='md:mx-30 lg:mx-60 py-2'
                            key={answerItem.id}>{answerItem.title}</p>
                    ))}
                </div>)
            })
            }</div>
    )
}
