import React from "react";
import WhyChoose from '../asset/why_choose.svg';
import Fhand from '../asset/flower_hand.svg';
import RGreen from '../asset/reduce_green.svg';
import NWater from '../asset/no_water.svg';
import RWater from '../asset/reduce_water.svg';

export default function Page2() {

  const titles = [
    {
      image: Fhand,
      title: "Conserve natural resources",
      meaning: "Our gas is a cleaner-burning fuel than some other fossil fuels, such as coal or oil."
    }, {
      image: RGreen,
      title: "Reduces greenhouse gas flow",
      meaning: "Reduce the amount of greenhouse gases being released into the atmosphere"
    },
    {
      image: NWater,
      title: "Reduces water usage",
      meaning: "generate electricity in a more water-efficient way than other sources of power"
    },
    {
      image: RWater,
      title: "Reduces air pollution",
      meaning: "You're sure of cleaner air around you with our gas"
    }

  ]
  return (
    <>
      <div className="flex-column text-white justify-center items-center bg-deep-green pb-10 md:hidden">
        <div className="items-center justify-center" id="why">
          <h1 className="text-4xl text-left md:text-6xl  md:font-bold p-5 font-bold" id="why-choose-title">Why choose <span className="text-green-500">Metl Gas?</span></h1>
          <img
            className="px-14 align-center"
            src={WhyChoose}
          />
        </div>
        <div className="grid grid-cols-2 gap-2 mt-5 pb-5">
          {titles.map(item => {
            return (<div className="mt-10 px-4">
              <img src={item.image} />
              <h1 className="text-xl font-bold py-2">{item.title}</h1>
              <p className="text-small text-gray-300">{item.meaning}</p>

            </div>)
          })}
        </div>
      </div>

      <div className="bg-deep-green text-white hidden md:block px-20 md:px-0 lg:px-40">
        <h1 className="text-4xl text-center md:font-bold p-5 font-bold">Why choose <span className="text-green-500">Metl Gas?</span></h1>
        <div style={{ display: "flex", justifyContent: "space-between", padding: "20px" }}>
          <div style={{ 
            flexDirection: "row", 
          justifyContent: "space-between" }}>
            <div className="">
              <img src={RGreen}
                style={{
                  marginLeft: "auto",
                  display: "block"
                }} />
              <h1 className="text-xl font-bold py-2 text-right w-52">{titles[1].title}</h1>
              <p className="text-small text-gray-300 text-right">Clean energy sources such as</p>
              <p className="text-small text-gray-300 text-right"> wind, solar, hydro, and</p>
              <p className="text-small text-gray-300 text-right"> geothermal power.</p>
            </div>

            <div className="py-10" >
              <img src={titles[3].image} alt="image" style={{
                marginLeft: "auto",
                display: "block", marginTop: "50px"
              }} />
              <h1 className="text-xl font-bold py-2 text-right w-52">{titles[3].title}</h1>
              <p className="text-small text-gray-300 text-right">Clean energy sources such as</p>
              <p className="text-small text-gray-300 text-right"> wind, solar, hydro, and</p>
              <p className="text-small text-gray-300 text-right"> geothermal power. </p>
            </div>

          </div>
          <img className="lg:w-1/2 md:w-2/4" src={WhyChoose} />
          <div className="">
            <div className="">
              <img src={titles[0].image} alt="image" />
              <h1 className="text-xl font-bold py-">Conserve natural <br />resources</h1>
              <p className="text-small text-gray-300">Clean energy sources such as</p>
              <p className="text-small text-gray-300">wind, solar, hydro, and</p>
              <p className="text-small text-gray-300">geothermal power. </p>

            </div>
            <div className="py-10"
              style={{
                marginTop: "50px"
              }}>
              <img src={titles[2].image} alt="image"/>
              <h1 className="text-xl font-bold">{titles[2].title}</h1>
              <p className="text-small text-gray-300">Clean energy sources such as</p>
              <p className="text-small text-gray-300">wind, solar, hydro, and</p>
              <p className="text-small text-gray-300">geothermal power.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
