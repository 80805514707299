import Header from "./components/header";
import Page1 from "./components/page1";
import Page2old from "./components/page2old";
import Page2Text from "./components/page2text";
import Page03 from "./components/page03";
import Page031 from "./components/page031";
import Page4 from "./components/page4";
import Partners from "./components/partners";
import Contact from "./components/contact";
import Footer from "./components/footer";
import Faq from "./components/faq";

function App() {
  return (
    <div className="overflow-hidden">
      <Header />
      <Page1 />
      <Partners />
      <Page2Text />
      <Page03 />
      <Page031 />
      <Page2old />
      <Faq/>
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
