import React from 'react'

export default function Input({type,placeholder,value,onChange}) {
  return (
    <div>
        <input 
        required
        type={type} 
        placeholder={placeholder}
        onChange={onChange}
        value={value}
         className="rounded-2xl px-5 py-3 border border-grey-500 my-2 w-full"/>
    </div>
  )
}
