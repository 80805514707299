import React from "react";
import PhoneCylinder from "../asset/phone_cylinder_update.svg";
import Qr from "../asset/qr_code.svg";
import PlayStore from '../asset/app-store.svg';
import AppStore from '../asset/app-store-2.svg';
export default function Page2() {

  return (
    <div id="download" className="-pt-80 md:-pt-44 lg:pl-36">
      <div id="two" className="md:mx-20 px-5 text-white bg-green-500 md:hidden">
        <h1 className="text-left text-4xl font-bold py-10" id="need_gas">Need Gas?</h1>
        <p className="text-3xl" id="download-text">Download <span className="font-bold"> Mezopay app</span> and have gas delivered to your doorstep</p>
        <div className="flex items-left w-full py-10">
          <a href='https://play.google.com/store/apps/details?id=com.mezovest.mezopay' target="_blank">
            <img className="m-2 w-40" src={AppStore} alt="screenshot" id="app-store" />
          </a>
          <a href='https://apps.apple.com/us/app/mezopay/id1569381406' target='_blank'>
            <img className="mr-10 w-36" src={PlayStore} alt="screenshot" id="app-store" />
          </a>
        </div>
        <img
          src={PhoneCylinder}
          alt="easy"
          className="px-0 py-5 -mr-20"
          id="phone-cylinder"
        />
        <img
          src={Qr}
          alt="qr"
          id='qr-scanner'
          className="align-center ml-20"
        />
        <h1 className="text-2xl w-full text-black font-bold text-center px-0 pb-10 pt-5">Scan with <span className="text-white">mobile phone</span><br />
          to download</h1>
      </div>


      <div id="two" className="hidden md:block mb-20 ml-40">
        <div style={{ maxHeight: "380px", position: "relative" }}>
          <img src={PhoneCylinder} id="phoneCyli" alt="image"
            style={{
              maxWidth: "100%",
              height: "450px",
              position: "relative",
              zIndex: "2",
            }} />
          <div className="flex bg-green-500 absolute inset-0 ml-20 mt-20">
            <div className="flex-1 flex flex-col"
               style={{ flex: "2" }}>
              <div className="pl-80 mt-5"
              id="need_gas_container" 
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                display: "block"
              }}>
                <h1 className="font-bold text-3xl text-white" id="ngas">Need Gas?</h1>
                <h1 className="text-2xl text-white py-2" id="down">
                  Download <span className="font-bold">Mezopay app</span> and have gas delivered to your doorstep
                </h1>
              </div>
              <div className="flex pl-80 mt-5" id="download_cont">
                <a href="https://play.google.com/store/apps/details?id=com.mezovest.mezopay" target="_blank" rel="noopener noreferrer">
                  <img src={AppStore} alt="screenshot" />
                </a>
                <a href="https://apps.apple.com/us/app/mezopay/id1569381406" target="_blank" rel="noopener noreferrer">
                  <img src={PlayStore} alt="screenshot" />
                </a>
              </div>
            </div>
            <div className="flex-1 flex justify-end items-center">
              <div style={{ textAlign: "center" }}>
                <img src={Qr} alt="qr" className="w-1/2" style={{
                  display: "block",
                  marginLeft: 'auto',
                  marginRight: 'auto'                  
                }} />
                <h1 className="font-bold text-xl pr-5" id="scan">
                  Scan with <span className="text-white">mobile phone</span><br/> to download
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div >
  );
}
