import React from 'react';

import R1 from '../asset/reviewer_1.svg'
import R2 from '../asset/reviewer_2.svg'
import R3 from '../asset/reviewer_3.svg'
import R4 from '../asset/reviewer_4.svg'
import R5 from '../asset/reviewer_5.svg'
import AV from '../asset/avatar.png'
import Wavebg from '../asset/wave_background.svg'

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


export default function Page3() {

  const items = [
    { id: 1, title: '"Metl\'s composite gas cylinder is a game-changer! It\'s lighter and more durable than traditional steel cylinders. I can easily carry it to my business place for my BBQ business." - John, 38.', image: AV },
    { id: 2, title: '"I switched to Metl\'s gas delivery service this year, and I have been impressed by their prompt and reliable service. I have never run out of gas in the middle of cooking." - Maria, 26.', image: AV },
    { id: 3, title: '"The composite cylinder is easy to maintain and doesn\'t rust. I no longer have to worry about corroded valves or leakages." - David, 41.', image: AV },
    { id: 4, title: '"Metl\'s gas refills are affordable and of high quality. I have recommended their service to my friends and family." - Sophia, 34.', image: AV },
    { id: 5, title: '"I love the sleek and modern design of Metl\'s composite cylinder. It complements the aesthetics of my kitchen perfectly." - Kelechi.', image: AV },
    // { id: 6, title: '"The composite cylinder is safe and eco-friendly. I feel good knowing that I\'m using a product that\'s good for the environment." - Ololade, 32.', image: R5 },
    // { id: 7, title: '"I have been using Metl\'s gas delivery service for over a year now, and I have had no complaints. The delivery guys are friendly and professional." - Chike, 44.', image: R5 },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3
  };
  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };


  return (
    <div id="3" className='py-10 pb-24 md:py-0 bg-cover bg-center mb-0 mt-14 lg:px-32' style={{ backgroundImage: `url(${Wavebg})` }}>
      <div className='justify-center md:mx-20 md:my-0 text-color-white px-10'>
        <h5 className='md:invisible text-4xl text-black text-left p-5 font-bold'>What <span className='text-green-500'>our customers </span> <br />say about us</h5>
        <h5 id="what-cust-says" className='invisible md:visible text-4xl text-black text-center pb-10 font-bold'>What our customers say  <br />about us</h5>

        <div className='hidden md:block pb-20'>
          <Slider  {...settings} className="hidden py-10 -md:py-20">
            {items.map(item => (
              <div key={item.id} className="px-3 ">
                <img src={item.image} alt={item.title} />
                <h3>{item.title}</h3>
              </div>
            ))}
          </Slider>
        </div>
        <div className='md:hidden -mt-36'>
          <Slider  {...settings2}>
            {items.map(item => (
              <div key={item.id} className="flex px-3 items-center justify-center align-center">
                <img src={item.image} alt={item.title} />
                <h3 className='text-center'>{item.title}</h3>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}
