import React from "react";
import Email from '../asset/email.svg';
import Call from '../asset/call.svg';
import Location from '../asset/location.svg';
import Mezopay from '../asset/mezopay.svg';
import Logo from '../asset/white_metl_logo.svg';

export default function Footer() {

  const contact_routes = [
    { image: Email, title: "Email Us", value: "metl@mezovest.com" },
    { image: Call, title: "Call Us", value: "+234 913 357 7245" },
    { image: Location, title: "Location", value: "No 36 Turnbull, Ikoyi, Lagos." }
  ]

  return (
    <div id="2" className="flex align-center justify-between lg:px-52 md:py-5 text-gray-200 bg-black px-3">
      <img src={Mezopay} className="position-absolute md:hidden" />
      <img src={Logo} className="position-absolute hidden md:block" />
      <p className="text-xs text-center md:px-5 py-3 px-5">©2023 Metl is a Mezovest company. All rights reserved </p>
      {/* <p className="text-xs hidden md:block md:px-5 py-3" id="footer-text-2">Privacy Policy  Terms of Use</p> */}
    </div>
  );
}
