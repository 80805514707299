import React from 'react';
import Tools from '../asset/tools.svg';
import GTank from '../asset/gas_tank.svg';
import CSafety from '../asset/cylinder_safety.svg';
import MWoman from '../asset/man_and_woman.svg';
import HLine from '../asset/horizontal_line.svg';
import GLine from '../asset/short_green_line.svg';

export default function Page3() {

  let tiles = [
    {
      title: "Gas Monitor installations",
      desc: "With our gas monitoring feature, be sure to get notifications on gas usage and when you're about to run out of gas",
      divider: Tools
    },
    {
      title: "Gas Cylinder Refills",
      desc: "You don't have to worry about queueing for refills. Just place your order, and you'll have it delivered",
      divider: GTank


    },
    {
      title: "Cylinder Safety Checks",
      desc: `We'll always send you safety check tips via emails, text messages, and our social media pages.`,
      divider: CSafety


    },

  ]
  return (
    <div id="1" className='md:flex justify-center md:py-20 lg:px-48 rounded-lg'>
      <div className='px-5 py-3 sm:hidden'>
        <h1 className='text-4xl font-bold sm:block text-left md:pl-10'>How we deliver <span className='text-green-400'>our</span> <br /></h1>
        <div className='flex'><p className='text-4xl text-green-400 font-bold'>promise</p> <img src={GLine} alt="line" className='ml-4' /></div>
      </div>

      <img src={MWoman} alt="customers"  className='px-0 w-2/2 md:w-1/2'/>

      <div className='md:pl-14' id='how-we'>
        <div className='hidden md:flex md:show flex align-center content-center'>
        {/* <div className='invisible md:visible md:show flex align-center content-center'> */}
          <img className='h-20' src={HLine} alt="divider" />
          <h1 className='text-4xl font-bold text-left md:pl-10'>How we <br />deliver our promise</h1>
        </div>
        {
          tiles.map((item, index) => {
            return (
              <div>
                <div className='flex md:pl-10 ml-3'>
                  <img src={item.divider} alt="" className='pl-2 md:p-5 md:p-0' />
                  <div className='pb-0 p-10 md:p-5'>
                    <p className='text-xl font-bold'>{item.title}</p>
                    <p className='mt-3 text-gray-500 text-medium md:pr-0'>{item.desc}</p>
                  </div>
                </div>
              </div>)
          })
        }
      </div>
    </div>
  )
}
