import React, { useState } from 'react'
import Metl from '../asset/metl_logo.svg'
import { Link } from 'react-scroll';

export default function Header() {
  const [active, setActive] = useState("5")
  const [open, setOpen] = useState(false)
  const features = [
    { id: "page1", name: "Home" },
    { id: "1", name: "Services" },
    { id: "3", name: "About Us" },
    { id: "have-a-question", name: "Contact" },
  ]
  return (
    <div className='flex text-black px-2 py-5 md:px-20 lg:px-52 justify-center flex-wrap justify-between md:flex shadow-md md:shadow-none'>
      <div>
        <div className='flex '>
          <img src={Metl} alt="icon" width={100} height={100} className="justify-center -mt-2 ml-5" />
        </div>

      </div>
      <div className='flex'>
        <ul className={!open ? 'flex-column items-center md:flex md:justify-center sm:flex-column hidden ease-out duration-300' : 'flex-column items-center md:flex md:justify-center sm:flex-column ease-in duration-300 '}>
          {features.map(item => <li className='py-5 md:py-0 cursor-pointer font-bold'>
            <Link activeClass={"3"}
              to={item.id}
              spy={true}
              smooth={true}
              duration={500}
              className='px-5 text-center'
              onClick={() => setActive(item.id)}
              style={{
                color: active === item.id ? "#26D506" : null,
                color: active === item.id && '#26D506',
                borderBottom: item.id === "5" ? "1px solid #F77F00" : null
              }}>{item.name}</Link></li>)}
        </ul>
      </div>
      <Link
        to='download'
        spy={true}
        smooth={true}
        duration={500}>
        <button id="bgas"
          className='bg-green-500 text-white font-bold'
          style={{
            borderRadius: '50px',
            padding: "5px 10px",
            fontSize: "15px"
          }}>
          Buy Gas Now
        </button>
      </Link>

    </div>
  )
}
