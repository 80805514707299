import React from "react";
import SS from '../asset/ss.svg';
import roll from '../asset/rolling.svg';
import terraform from '../asset/terraform.svg';
import jay from '../asset/jay.svg';
import Ohla from '../asset/ohlala.svg';
// import George from '../asset/george.svg';
import Wbar from '../asset/wbar.svg';
import Johnny from '../asset/Johnny.svg';
import Pearl from '../asset/Pearl.svg';
import Slider from "react-slick";

export default function Partners() {

  const items = [
    { id: 1, image: roll },
    { id: 2, image: terraform },
    { id: 3, image: jay },
    { id: 4, image: Ohla },
    // { id: 5, image: George },
    { id: 6, image: Wbar },
    { id: 7, image: Johnny },
    { id: 8, image: Pearl },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 6
  };
  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3
  };
  return (
    <div className=" md:items-center md:justify-center pb-20 md:px-40 lg:px-52">
      <div>
        <h1 className="text-3xl md:text-center font-bold px-5" >Trusted by <span className="text-green-500">100+</span>  <br className="md:hidden" /> Companies</h1>
        <p className="px-5 text-left py-8 md:text-center">We currently serve a variety of businesses and customers, who are satisfied with our services.</p>
      </div>

      {/* <div className='justify-center md:mx-20 md:my-0 text-color-white'> */}


        <div className='hidden md:block pb-10 px-40'>
          <Slider  {...settings} className="hidden py-10 -md:py-20">
            {items.map(item => (
              <div key={item.id} className="px-3 flex items-center justify-center align-center">
                <img src={item.image}/>
              </div>
            ))}
          </Slider>
        </div>
        <div className='md:hidden px-10'>
          <Slider  {...settings2}>
            {items.map(item => (
              <div key={item.id} className="">
                <img src={item.image}/>
              </div>
            ))}
          </Slider>
        </div>
      {/* </div> */}
      {/* <div className="md:justify-center px-10 md:py-5 py-4 my-10 text-center flex w-full overflow-x-scroll">
        <div className="flex md:justify-center px-10">
          <img src={SS} className="md:w-32 px-10" />
          <img className="md:w-28 px-4" src={roll} />
          <img className="md:w-28 px-4" src={terraform} />
          <img className="md:w-28 px-4" src={jay} />
          <img className="md:w-28 px-4" src={Ohla} />
          <img className="md:w-28 px-4" src={George} />
          <img className="md:w-28 px-4" src={Wbar} />
          <img className="md:w-28 px-4" src={Johnny} />
          <img className="md:w-28 px-4" src={Pearl} />
        </div>
      </div> */}


    </div>
  );
}
