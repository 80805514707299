import React, { useState } from "react";
import Email from '../asset/email.svg';
import Call from '../asset/call.svg';
import Location from '../asset/location.svg';
import Twitter from '../asset/twitter.svg';
import Facebook from '../asset/facebook.svg';
import Instagram from '../asset/instagram.svg';
import Arrow from '../asset/arrow.svg';
import Input from "./input";
import axios from 'axios';
import {toast} from 'react-toastify';
export default function Contact() {

  const contact_routes = [
    { image: Email, title: "Email Us", value: "metl@mezovest.com" },
    { image: Call, title: "Call Us", value: "+234 913 357 7245" },
    { image: Location, title: "Location", value: "No 36 Turnbull, Ikoyi, Lagos." }
  ]

  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [message, setMessage] = useState('')
  const recaptchaRef = React.createRef();


  const handleSendMail = (e) => {
    e.preventDefault()
//    recaptchaRef.current.executeAsync(); // Execute reCAPTCHA

    let data = {
      email: email,
      name: name,
      message: message,
      source:'metl'

    }
    if (email.length === 0 || name.length === 0 || message.length === 0) {
      toast.error("All fields are required")

    } else {
      if (recaptchaRef.current) {
        recaptchaRef.current.executeAsync(); // Execute reCAPTCHA
     }      
       axios.post('https://api.community.mezovest.com/send-mail-2', data)
          .then(data => {
            console.log(data)
            toast.success("Thank you for reaching out. You will be contacted by one of our team members within 24hrs")
            setMessage('')
            setEmail('')
            setName('')
          }).catch(err => {
            toast.error("An error occurred, Please try again later")
            console.log(err)
          })
    }
  }


  return (
    <div id="have-a-question" className="flex flex-col md:flex-row text-black bg-white-500 py-10 md:px-44">
      {/* <img src={HAQ} alt="question" className="hidden lg:block h-1/7 w-1/2 flex-shrink-0" style={{ alignSelf: "stretch" }} /> */}


      {/* <div className='md:flex my-20 lg:px-56' id='4'> */}
      {/* <div className='hidden'>
            <h3 className='text-5xl'>Leave a message</h3>
            <p className='text-xl'>Please fill in all required fields.</p>
            <Input placeholder={"Name"} type={"text"} value={name} onChange={(e) => setName(e.target.value)} />
            <Input placeholder={"Email"} type={"text"} value={email} onChange={(e) => setEmail(e.target.value)} />
            <textarea required placeholder={'Message'} value={message} onChange={(e) => setMessage(e.target.value)} />
            <Button title={"Submit"} onClick={handleSendMail} />
          </div> */}
      {/* <div className='flex flex-col w-full md:flex-row-reverse md:pr-10 px-5'> */}
      <div className='flex w-full flex-col md:pr-10 px-3'>

        {/* <div className='px-5 mx-0 md:mx-0 md:w-1/2 rounded-3xl overflow-hidden shadow-lg bg-white py-10' id="mbox"> */}
        <div className='px-5 mx-0 md:mx-0 rounded-3xl overflow-hidden shadow-lg bg-white py-10' id="mbox">
          <h3 className='font-bold text-5xl md:hidden'>Leave a <br /><span className='text-green-500'>message</span></h3>
          <h3 className='font-bold text-5xl hidden md:block'>Leave a <span className='text-green-500'>message</span></h3>
          <p className='py-3 text-xl'>Please fill in all required fields.</p>
          <Input placeholder={"Name"} type={"text"} value={name} onChange={(e) => setName(e.target.value)} />
          <Input placeholder={"Email"} type={"text"} value={email} onChange={(e) => setEmail(e.target.value)} />
          <textarea
            required
            value={message} onChange={(e) => setMessage(e.target.value)}
            placeholder={'Message'}
            className='rounded-2xl px-5 py-3 border border-grey-500 my-2 w-full' style={{ minHeight: '150px' }} />
          <button
            onClick={handleSendMail}
            title={"Submit"}
            className={'bg-green-500 rounded-3xl font-bold text-white px-8 py-2'}>
            Submit
          </button>
        </div>
      </div>
      <div className="md:ml-20 w-full px-2 flex flex-col justify-center px-3" style={{ alignItems: "stretch" }}>
        <h1 className="text-left text-5xl font-medium py-5">Have a <br className="md:hidden" /><span className="text-green-500">question?</span></h1>
        <p className="text-xl pb-5">Our customer service is always available to reply all queries </p>
        {
          contact_routes.map((item, index) => {
            return (
              <>{
                index === 1 ? (<a href={`tel:${item.value}`} className="text-black-1000">
                  <div className="flex">
                    <img className="pr-5 text-black" src={item.image} alt="screenshot" />
                    <div className="p-3">
                      <p className="font-bold">{item.title}</p>
                      <p>{item.value}</p>
                    </div>
                  </div>
                </a>) : index === 0 ? (
                  <a href={`mailto:${item.value}`} className="text-black-1000">
                    <div className="flex">
                      <img className="pr-5 text-black" src={item.image} alt="screenshot" />
                      <div className="p-3">
                        <p className="font-bold">{item.title}</p>
                        <p>{item.value}</p>
                      </div>
                    </div>
                  </a>) :
                  (<div className="flex">
                    <img className="pr-5" src={item.image} alt="screenshot" />
                    <div className="p-3">
                      <p className="font-bold">{item.title}</p>
                      <p>{item.value}</p>
                    </div>
                  </div>)
              }
              </>
            )
          })
        }
        <div className="flex pt-10 pb-10 pl-5">
          <a href="https://twitter.com/mezoenergy" target="_blank"><img className="mr-10" src={Twitter} alt="twitter" /></a>
          <a href="https://facebook.com/mezoenergy" target="_blank"><img className="mr-10" src={Facebook} alt="facebook" /></a>
          <a href="https://instagram.com/mezoenergy" target="_blank"><img className="mr-10" src={Instagram} alt="instagram" /></a>
          <img className="mr-10" src={Arrow} alt="arrow" />
        </div>
      </div>

      {/* </div> */}
      {/* <div className="md:ml-20  px-2 flex flex-col justify-center" style={{ alignItems: "stretch" }}>
        <h1 className="text-left text-5xl font-medium py-5">Have a <br className="md:hidden" /><span className="text-green-500">question?</span></h1>
        <p className="text-xl pb-5">Our customer service is always available to reply all queries </p>
        {
          contact_routes.map((item, index) => {
            return (
              <>{
                index === 1 ? (<a href={`tel:${item.value}`} className="text-black-1000">
                  <div className="flex">
                    <img className="pr-5 text-black" src={item.image} alt="screenshot" />
                    <div className="p-3">
                      <p className="font-bold">{item.title}</p>
                      <p>{item.value}</p>
                    </div>
                  </div>
                </a>) : index === 0 ? (
                  <a href={`mailto:${item.value}`} className="text-black-1000">
                    <div className="flex">
                      <img className="pr-5 text-black" src={item.image} alt="screenshot" />
                      <div className="p-3">
                        <p className="font-bold">{item.title}</p>
                        <p>{item.value}</p>
                      </div>
                    </div>
                  </a>) :
                  (<div className="flex">
                    <img className="pr-5" src={item.image} alt="screenshot" />
                    <div className="p-3">
                      <p className="font-bold">{item.title}</p>
                      <p>{item.value}</p>
                    </div>
                  </div>)
              }
              </>
            )
          })
        }
        <div className="flex pt-10 pb-10 pl-5">
          <a href="https://twitter.com/mezoenergy" target="_blank"><img className="mr-10" src={Twitter} alt="twitter" /></a>
          <a href="https://facebook.com/mezoenergy" target="_blank"><img className="mr-10" src={Facebook} alt="facebook" /></a>
          <a href="https://instagram.com/mezoenergy" target="_blank"><img className="mr-10" src={Instagram} alt="instagram" /></a>
          <img className="mr-10" src={Arrow} alt="arrow" />
        </div>
      </div> */}
    </div>


  );
}