import React from "react";
import Page1Line from '../asset/page_1_line.svg';
import PlayStore from '../asset/app-store.svg';
import AppStore from '../asset/app-store-2.svg';
import Cylinder from '../asset/cylinder-with-leaves.svg';
// import GasLeaf from '../asset/gas2.svg';
import GasLeaf from '../asset/gascy.png';
import GasLeaf2 from '../asset/cylinder_v2.png';
import GasLeaf2_1 from '../asset/gas2_1.png';
import Leaf from '../asset/leaf.svg';

export default function Page1() {

  return (
    <div className="flex py-10 md:-py-20 md:px-16 lg:px-52 justify-between" id="page1">
      <div className="pl-2 md:py-28" id="page_1_parent">
        <div className="text-5xl font-bold align-center md:hidden" id="page_1_title">
          <h1 id="hq">High</h1>
          <h1 id="hq">Quality</h1>
          <h1 id="hq" className="text-green-500">Metl</h1>
          <h1 id="hq">Cooking</h1>
          <h1 id="hq">Gas</h1>
        </div>
        <img className="absolute -left-10 lg:left-20 hidden lg:block" src={Leaf} alt="leaf" />

        <div className="hidden md:block" id="big-epital">
          <h1 id="h1-cooking-gas" className="text-5xl  md:font-bold">High Quality <br /><span className="text-green-500">Metl</span>  Cooking Gas</h1>
          <p className="text-gray-400 md:pt-5">We offer the best high-quality, durable,</p>
          <p className="text-gray-400">greenhouse, eco-friendly LPG products.</p>
        </div>

        <div className="text-gray-400 pt-5 md:hidden">
          <p className="">We offer the best high-</p>
          <p className="">quality, durable,greenhouse,</p>
          <p className="">eco-friendly LPG products.</p>
        </div>
        <img className="py-5" src={Page1Line} alt="screenshot" />
        <div className="md:flex">
          <div className="eco_parent">
            <h1 className="text-2xl font-bold text-green-500">Eco-friendly</h1>
            <p className="p1  md:font-small md:py-2">Conserve natural resources</p>
            <div className="pt-2 text-gray-400" ><p>It's not damaging to the natural environment.</p>
              {/* <p>hydro, and geothermal power.</p> */}
            </div>
          </div>
          <div className="md:pl-10" id='safe_parent'>
            <h1 className="text-2xl font-bold text-green-500 pt-5 md:pt-0">Safe</h1>
            <h2 className="pt-2 text-base font-small md:py-2">High Quality Technology</h2>
            <p className="text-md pt-2 text-gray-400">Our tech solution makes it easy for you to use and track.,</p>
            {/* <p className="text-gray-400">solar, hydro, and geothermal power.</p> */}
          </div>
        </div>
        <div className="flex pt-10 pb-10">
          <a className="md:mr-5"
            href='https://play.google.com/store/apps/details?id=com.mezovest.mezopay'
            target="_blank" rel="noreferrer">
            <img src={AppStore} alt="screenshot"  />
          </a>
          <a href='https://apps.apple.com/us/app/mezopay/id1569381406'
            className='ml-4 md:ml-0'
            target='_blank' rel="noreferrer">
            <img src={PlayStore} alt="screenshot" />
          </a>
        </div>


      </div>
      <img className="md:hidden  z-10  -ml-20 mt-0" src={GasLeaf2_1} alt="cylinder" 
       style={{right : -30,height:'400px',width:'500px', overflowX:'hidden'}}
       />
      {/* <img className="h-full -ml-100 z-10 md:hidden mt-14" src={GasLeaf} alt="cylinder" id="cylinder" /> */}
      {/* <img className="h-full -mr-16 hidden sm:block" id="big-cylinder" src={GasLeaf} alt="cylinder" /> */}
      <img className="h-full w-2/3 lg:-mr-64 md:-mr-40 hidden sm:block" id="big-cylinder" src={GasLeaf2} alt="cylinder" />
    </div>
  );
}
